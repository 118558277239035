import { UploadOutlined } from '@ant-design/icons';
import { ImageLibrary } from '@components/image-library';
import { Button } from 'antd';
import { useState } from 'react';

type Props = {
	id?: string;
	onChange?: (imageUrl: string) => void;
};

function ImageLibraryFormInput({ id, onChange }: Props) {
	const [imageLibraryOpen, setImageLibraryOpen] = useState(false);

	return (
		<div id={id}>
			<Button onClick={() => setImageLibraryOpen(true)} icon={<UploadOutlined />}>
				Välj bild
			</Button>
			<ImageLibrary
				isOpen={imageLibraryOpen}
				onClose={() => setImageLibraryOpen(false)}
				onSelect={({ imageSrc }) => {
					onChange?.(imageSrc);
				}}
			/>
		</div>
	);
}

export default ImageLibraryFormInput;
