import PriceLabel, { PriceType } from '../../PriceLabel';
import { OfferInstagram } from '../../types';

interface Props {
	offers: OfferInstagram[];
}

const _1inInstagram = ({ offers }: Props) => {
	return (
		<div className="insta-one-print">
			{offers.map((offer) => (
				<div key={offer.id} className="insta-four-offer">
					<div className="insta-one-image-container">
						{offer.imageUrl && (
							<img className="insta-four-image" src={offer.imageUrl} style={{ maxHeight: 620 * offer.imageScale }} />
						)}
					</div>
					<div className="insta-two-content-two">
						<h4 className="insta-four-product">{offer.product}</h4>
						<pre className="insta-four-extra">{offer.extra}</pre>
						<div style={{ width: '100%', height: 2, background: 'black', marginTop: 6 }} />
					</div>
					<div className="insta-one-price-container">
						{offer.type === PriceType.Price && (
							<PriceLabel
								type="price"
								price={offer.price}
								showPriceDecimals={offer.priceShowDecimals}
								unit={offer.unit}
							/>
						)}
						{offer.type === PriceType.PieceForPiece && (
							<PriceLabel
								type="price-for-piece"
								first={offer.first}
								second={offer.second}
								singlePrice={offer.singlePrice}
							/>
						)}
						{offer.type === PriceType.AmountForPrice && (
							<PriceLabel
								type="amount-for-price"
								quantity={offer.quantity}
								price={offer.price}
								showPriceDecimals={offer.priceShowDecimals}
								save={offer.save}
								showSaveDecimals={offer.saveShowDecimals}
							/>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default _1inInstagram;
