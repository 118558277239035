export const styleContent = `
@font-face {
  font-family: Glasgow-Regular;
  src: url(assets/fonts/glasgow/Glasgow-Regular.woff);
}
@font-face {
  font-family: Glasgow-DemiBold;
  src: url(assets/fonts/glasgow/Glasgow-DemiBold.woff);
}
@font-face {
  font-family: Glasgow-ExtraBold;
  src: url(assets/fonts/glasgow/Glasgow-ExtraBold.woff);
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.preview {
  position: relative;
  height: 100%;
  width: 100%;
}

.pt-price-label-print {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.pt-price-label-offer {
  position: relative;
  overflow: hidden;
}

.pt-price-label-banner {
  background-image: url('assets/price-label-background.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 230px;
  position: absolute;
  z-index: 0;
}
.pt-price-label-banner-container {
  position: absolute;
  margin-left: 50px;
  margin-top: 36px;
  width: 120mm;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.pt-price-label-logo {
  height: 32px;
  position: absolute;
  top: 8px;
  right: 8px;
  margin-bottom: -2px;
}

.pt-price-label-title {
  line-height: 70px;
  font-family: 'Glasgow-ExtraBold', sans-serif;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.pt-price-label-content {
  max-width: 455px;
  margin-left: 50px;
  position: absolute;
  bottom: 20px;
}

.pt-price-label-product {
  font-family: 'Glasgow-DemiBold', sans-serif;
  font-size: 28px;
  color: #000;
  margin: 0;
}

.pt-price-label-extra {
  font-family: 'Glasgow-DemiBold', sans-serif;
  font-size: 16px;
  color: #407c54;
  margin: 0;
  line-height: 18px;
}

.pt-price-label-image-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  width: 200px;
  height: 190px;
  background: transparent;
}

.pt-price-label-image {
  max-width: 200%;
}

.price {
  margin: 0;
  color: #ffffff;
  font-family: 'Glasgow-DemiBold', sans-serif;
}
`;
