import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, InputRef, Modal, Space } from 'antd';
import { Radio } from 'antd/lib';
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import { categories, images } from './images';
import { CategoryId, Image } from './types';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onSelect: ({ imageSrc, file }: { imageSrc: string; file: File }) => void;
};

const categoryOptions = [
	{ label: 'Alla', value: 'all' },
	...categories.map((category) => ({ label: category.name, value: category.id })),
];

function ImageLiberary({ isOpen, onClose, onSelect }: Props) {
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const searchTermRef = useRef<InputRef | null>(null);

	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedCategory, setSelectedCategory] = useState<CategoryId | 'all'>('all');

	const filteredImages = getFilteredImages(images, searchTerm, selectedCategory);

	useEffect(() => {
		// Don't know why this timeout is needed for the autofocus.
		const timeoutId = setTimeout(() => {
			if (isOpen) searchTermRef.current?.focus();
		}, 100);

		return () => {
			clearTimeout(timeoutId);
		};
	}, [isOpen]);

	async function onOk() {
		if (!selectedImage) return;

		const file = await getFileFromUrl(selectedImage);
		if (!file) return;

		onSelect({
			imageSrc: selectedImage,
			file: file,
		});
		onClose();
	}

	function onFileInputChange(e: React.ChangeEvent<HTMLInputElement>) {
		const file = e.target.files?.[0];
		if (!file) return;

		onSelect({
			file: file,
			imageSrc: URL.createObjectURL(file),
		});
		onClose();
	}

	return (
		<Modal
			className={styles.modal}
			centered
			width="auto"
			title="Välj bild"
			open={isOpen}
			onOk={onOk}
			okButtonProps={{
				disabled: !selectedImage,
			}}
			okText="Välj bild"
			onCancel={onClose}
		>
			<Space style={{ width: '100%' }} direction="vertical">
				<Input
					ref={searchTermRef}
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					size="large"
					placeholder="Sök"
					prefix={<SearchOutlined />}
				/>
				<Radio.Group
					value={selectedCategory}
					onChange={(e) => setSelectedCategory(e.target.value)}
					block
					options={categoryOptions}
					defaultValue="Apple"
					optionType="button"
					buttonStyle="solid"
				/>
			</Space>
			<div className={styles.results}>
				{filteredImages.map((image, i) => (
					<button
						onClick={() => setSelectedImage(image.src)}
						key={i}
						className={`${styles.result} ${image.src === selectedImage ? styles.selectedResult : null}`}
					>
						<img className={styles.image} src={image.src} />
						<div className={styles.name} title={image.name}>
							{image.name}
						</div>
					</button>
				))}
			</div>
			<input
				accept="image/*"
				ref={fileInputRef}
				onChange={onFileInputChange}
				style={{ visibility: 'hidden' }}
				type="file"
			/>
			<Button icon={<UploadOutlined />} size="large" onClick={() => fileInputRef.current?.click()} block>
				<b>Saknas bilden? Klicka här för att välja en egen bild</b>
			</Button>
		</Modal>
	);
}

export default ImageLiberary;

function getFilteredImages(images: Image[], searchTerm: string, selectedCategory: CategoryId | 'all') {
	let filteredImages = images;

	filteredImages = filteredImages.filter((image) =>
		image.name.toLowerCase().normalize('NFC').includes(searchTerm.toLowerCase().normalize('NFC'))
	);

	filteredImages =
		selectedCategory === 'all'
			? filteredImages
			: filteredImages.filter((image) => image.categories.some((categoryId) => categoryId === selectedCategory));

	filteredImages.sort((a, b) => a.name.localeCompare(b.name, 'sv'));

	return filteredImages;
}

async function getFileFromUrl(url: string): Promise<File | null> {
	const response = await fetch(url);
	if (!response.ok) return null;
	const blob = await response.blob();
	const filename = decodeURIComponent(url.split('/').reverse()[0]);
	const file = new File([blob], filename || 'downloaded_file', { type: blob.type });
	return file;
}
