import { useLayoutEffect, useRef } from 'react';
import logo from '../../../assets/images/logo-white.png';
import createShadowRoot from '../ShadowRoot';
import { styleContent } from './styles/styleContent';
import ResizableTextWrapper from '@components/resizeable-text/ResizableTextWrapper';
import { previewUpdateDelay } from '../../functions/print';
import { OfferPriceLabel } from '../types';

const Style = () => <style>{styleContent}</style>;

interface Props {
	offers: OfferPriceLabel[];
	iFrameId: string;
	show24FoodLogo: boolean;
}

const Preview = ({ show24FoodLogo, offers, iFrameId }: Props) => {
	const previewRef = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const iframe = document.getElementById(iFrameId) as HTMLIFrameElement | null;
		if (iframe) {
			const iframeDocument = iframe.contentDocument;
			if (iframeDocument) {
				const iframeBody = iframeDocument.body;
				if (iframeBody) {
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
					setTimeout(() => {
						iframeBody.innerHTML = previewRef.current?.outerHTML || '';
					}, previewUpdateDelay);
				}
			}
		}
	});

	return (
		<div ref={previewRef} className="preview">
			<Style />
			<div className="pt-price-label-print">
				{offers.map((offer) => (
					<div key={offer.id} className="pt-price-label-offer">
						<div className="pt-price-label-banner" />
						{show24FoodLogo && <img className="pt-price-label-logo" src={logo} />}
						<div className="pt-price-label-banner-container">
							<ResizableTextWrapper
								textComponent={<h1 className="pt-price-label-title">{offer.title}</h1>}
								containerSize="100%"
								initialFontSize="70px"
							/>
							<ResizableTextWrapper
								textComponent={<h1 className="price">{offer.price}</h1>}
								containerSize="100%"
								initialFontSize="40px"
							/>
						</div>
						<div className="pt-price-label-content">
							<pre className="pt-price-label-extra">{offer.extra}</pre>
						</div>
						<div className="pt-price-label-image-container">
							{offer.imageUrl && (
								<img
									className="pt-price-label-image"
									src={offer.imageUrl}
									style={{ maxHeight: 240 * offer.imageScale }}
								/>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default createShadowRoot(Preview);
