import { Category, Image } from './types';
import ananas from '../../assets/images/image-library/Ananas.png';
import apelsin from '../../assets/images/image-library/Apelsin.png';
import aubergine from '../../assets/images/image-library/Aubergine.png';
import appleGrona from '../../assets/images/image-library/Apple grona.png';
import applen from '../../assets/images/image-library/Applen.png';
import babyspenat from '../../assets/images/image-library/Babyspenat.png';
import banan from '../../assets/images/image-library/Banan.png';
import basilika from '../../assets/images/image-library/Basilika.png';
import blomkal from '../../assets/images/image-library/Blomkal.png';
import broccoli from '../../assets/images/image-library/Broccoli.png';
import champinjon from '../../assets/images/image-library/Champinjon.png';
import chilipeppar from '../../assets/images/image-library/Chilipeppar.png';
import citron from '../../assets/images/image-library/Citron.png';
import cocktailtomater from '../../assets/images/image-library/Cocktailtomater.png';
import galiamelon from '../../assets/images/image-library/Galiamelon.png';
import grapefruit from '../../assets/images/image-library/Grapefruit.png';
import graslok from '../../assets/images/image-library/Graslok.png';
import gronkal from '../../assets/images/image-library/Gronkal.png';
import gullok from '../../assets/images/image-library/Gullok.png';
import gurka from '../../assets/images/image-library/Gurka.png';
import honungsmelon from '../../assets/images/image-library/Honungsmelon.png';
import ingefara from '../../assets/images/image-library/Ingefara.png';
import isbergssallad from '../../assets/images/image-library/Isbergssallad.png';
import kalrot from '../../assets/images/image-library/Kalrot.png';
import kiwi from '../../assets/images/image-library/Kiwi.png';
import koriander from '../../assets/images/image-library/Koriander.png';
import korsbarstomater from '../../assets/images/image-library/Korsbarstomater.png';
import kvisttomater from '../../assets/images/image-library/Kvisttomater.png';
import lime from '../../assets/images/image-library/Lime.png';
import majskolv from '../../assets/images/image-library/Majskolv.png';
import mandarin from '../../assets/images/image-library/Mandarin.png';
import mandelpotatis from '../../assets/images/image-library/Mandelpotatis.png';
import mango from '../../assets/images/image-library/Mango.png';
import nektarin from '../../assets/images/image-library/Nektarin.png';
import palsternacka from '../../assets/images/image-library/Palsternacka.png';
import paprika from '../../assets/images/image-library/Paprika.png';
import passionsfrukt from '../../assets/images/image-library/Passionsfrukt.png';
import paron from '../../assets/images/image-library/Paron.png';
import persilja from '../../assets/images/image-library/Persilja.png';
import purjolok from '../../assets/images/image-library/Purjolok.png';
import radisor from '../../assets/images/image-library/Radisor.png';
import romansallad from '../../assets/images/image-library/Romansallad.png';
import rodbetor from '../../assets/images/image-library/Rodbetor.png';
import rodkal from '../../assets/images/image-library/Rodkal.png';
import rodlok from '../../assets/images/image-library/Rodlok.png';
import ruccola from '../../assets/images/image-library/Ruccola.png';
import satsumas from '../../assets/images/image-library/Satsumas.png';
import schallottenLok from '../../assets/images/image-library/Schallotten lok.png';
import sotpotatis from '../../assets/images/image-library/Sotpotatis.png';
import spetskal from '../../assets/images/image-library/Spetskal.png';
import spetspaprika from '../../assets/images/image-library/Spetspaprika.png';
import timjan from '../../assets/images/image-library/Timjan.png';
import tomater from '../../assets/images/image-library/Tomater.png';
import vattenmelon from '../../assets/images/image-library/Vattenmelon.png';
import vindruvorGrona from '../../assets/images/image-library/Vindruvor grona.png';
import vindruvorRoda from '../../assets/images/image-library/Vindruvor roda.png';
import vitkal from '../../assets/images/image-library/Vitkal.png';
import vitlok from '../../assets/images/image-library/Vitlok.png';
import zucchini from '../../assets/images/image-library/Zucchini.png';
import avokado from '../../assets/images/image-library/avokado.png';
import fankal from '../../assets/images/image-library/fankal.png';
import morotter from '../../assets/images/image-library/morotter.png';
import potatis from '../../assets/images/image-library/potatis.png';
import salladslok from '../../assets/images/image-library/salladslok.png';

export const categories: Category[] = [
	{
		id: 'vegetable',
		name: 'Grönsaker',
	},
	{
		id: 'fruit',
		name: 'Frukt',
	},
	{
		id: 'herb',
		name: 'Örter',
	},
];

export const images: Image[] = [
	{
		src: ananas,
		name: 'Ananas',
		categories: ['fruit'],
	},
	{
		src: apelsin,
		name: 'Apelsin',
		categories: ['fruit'],
	},
	{
		src: aubergine,
		name: 'Aubergine',
		categories: ['vegetable'],
	},
	{
		src: appleGrona,
		name: 'Äpple gröna',
		categories: ['fruit'],
	},
	{
		src: applen,
		name: 'Äpplen',
		categories: ['fruit'],
	},
	{
		src: babyspenat,
		name: 'Babyspenat',
		categories: ['vegetable'],
	},
	{
		src: banan,
		name: 'Banan',
		categories: ['fruit'],
	},
	{
		src: basilika,
		name: 'Basilika',
		categories: ['herb'],
	},
	{
		src: blomkal,
		name: 'Blomkål',
		categories: ['vegetable'],
	},
	{
		src: broccoli,
		name: 'Broccoli',
		categories: ['vegetable'],
	},
	{
		src: champinjon,
		name: 'Champinjon',
		categories: ['fruit'],
	},
	{
		src: chilipeppar,
		name: 'Chilipeppar',
		categories: ['vegetable'],
	},
	{
		src: citron,
		name: 'Citron',
		categories: ['fruit'],
	},
	{
		src: cocktailtomater,
		name: 'Cocktailtomater',
		categories: ['vegetable'],
	},
	{
		src: galiamelon,
		name: 'Galiamelon',
		categories: ['fruit'],
	},
	{
		src: grapefruit,
		name: 'Grapefruit',
		categories: ['fruit'],
	},
	{
		src: graslok,
		name: 'Gräslök',
		categories: ['herb'],
	},
	{
		src: gronkal,
		name: 'Grönkål',
		categories: ['vegetable'],
	},
	{
		src: gullok,
		name: 'Gullök',
		categories: ['vegetable'],
	},
	{
		src: gurka,
		name: 'Gurka',
		categories: ['vegetable'],
	},
	{
		src: honungsmelon,
		name: 'Honungsmelon',
		categories: ['fruit'],
	},
	{
		src: ingefara,
		name: 'Ingefära',
		categories: ['herb'],
	},
	{
		src: isbergssallad,
		name: 'Isbergssallad',
		categories: ['vegetable'],
	},
	{
		src: kalrot,
		name: 'Kålrot',
		categories: ['vegetable'],
	},
	{
		src: kiwi,
		name: 'Kiwi',
		categories: ['fruit'],
	},
	{
		src: koriander,
		name: 'Koriander',
		categories: ['herb'],
	},
	{
		src: korsbarstomater,
		name: 'Körsbärstomater',
		categories: ['vegetable'],
	},
	{
		src: kvisttomater,
		name: 'Kvisttomater',
		categories: ['vegetable'],
	},
	{
		src: lime,
		name: 'Lime',
		categories: ['fruit'],
	},
	{
		src: majskolv,
		name: 'Majskolv',
		categories: ['vegetable'],
	},
	{
		src: mandarin,
		name: 'Mandarin',
		categories: ['fruit'],
	},
	{
		src: mandelpotatis,
		name: 'Mandelpotatis',
		categories: ['vegetable'],
	},
	{
		src: mango,
		name: 'Mango',
		categories: ['fruit'],
	},
	{
		src: nektarin,
		name: 'Nektarin',
		categories: ['fruit'],
	},
	{
		src: palsternacka,
		name: 'Palsternacka',
		categories: ['vegetable'],
	},
	{
		src: paprika,
		name: 'Paprika',
		categories: ['vegetable'],
	},
	{
		src: passionsfrukt,
		name: 'Passionsfrukt',
		categories: ['fruit'],
	},
	{
		src: paron,
		name: 'Päron',
		categories: ['fruit'],
	},
	{
		src: persilja,
		name: 'Persilja',
		categories: ['herb'],
	},
	{
		src: purjolok,
		name: 'Purjolök',
		categories: ['vegetable'],
	},
	{
		src: radisor,
		name: 'Rädisor',
		categories: ['vegetable'],
	},
	{
		src: romansallad,
		name: 'Romansallad',
		categories: ['vegetable'],
	},
	{
		src: rodbetor,
		name: 'Rödbetor',
		categories: ['vegetable'],
	},
	{
		src: rodkal,
		name: 'Rödkål',
		categories: ['vegetable'],
	},
	{
		src: rodlok,
		name: 'Rödlök',
		categories: ['vegetable'],
	},
	{
		src: ruccola,
		name: 'Ruccola',
		categories: ['vegetable'],
	},
	{
		src: satsumas,
		name: 'Satsumas',
		categories: ['fruit'],
	},
	{
		src: schallottenLok,
		name: 'Schallotten lök',
		categories: ['vegetable'],
	},
	{
		src: sotpotatis,
		name: 'Sötpotatis',
		categories: ['vegetable'],
	},
	{
		src: spetskal,
		name: 'Spetskål',
		categories: ['vegetable'],
	},
	{
		src: spetspaprika,
		name: 'Spetspaprika',
		categories: ['vegetable'],
	},
	{
		src: timjan,
		name: 'Timjan',
		categories: ['herb'],
	},
	{
		src: tomater,
		name: 'Tomater',
		categories: ['vegetable'],
	},
	{
		src: vattenmelon,
		name: 'Vattenmelon',
		categories: ['fruit'],
	},
	{
		src: vindruvorGrona,
		name: 'Vindruvor gröna',
		categories: ['fruit'],
	},
	{
		src: vindruvorRoda,
		name: 'Vindruvor röda',
		categories: ['fruit'],
	},
	{
		src: vitkal,
		name: 'Vitkål',
		categories: ['vegetable'],
	},
	{
		src: vitlok,
		name: 'Vitlök',
		categories: ['vegetable'],
	},
	{
		src: zucchini,
		name: 'Zucchini',
		categories: ['vegetable'],
	},
	{
		src: avokado,
		name: 'Avokado',
		categories: ['vegetable'],
	},
	{
		src: fankal,
		name: 'Fänkål',
		categories: ['vegetable'],
	},
	{
		src: morotter,
		name: 'Morötter',
		categories: ['vegetable'],
	},
	{
		src: potatis,
		name: 'Potatis',
		categories: ['vegetable'],
	},
	{
		src: salladslok,
		name: 'Salladslök',
		categories: ['vegetable'],
	},
];
